@use "@pa/lib-spa/assets/scss/themes" as themes;
// General Style and Icons
$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
@import "@fortawesome/fontawesome-pro/scss/light.scss";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

body {
    background-size: cover;
}

.root {
    padding: 50px;
}

.paLogo {
    height: auto;
    width: 100%;
    max-height: 50px;
    max-width: 75px;
    border-radius: 0px;
}

a.btn.btn-primary {
    color: white;
}

.footer {
    font-size: small;
    color: #999;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 4px 8px;
    border-radius: 8px;
}

.navbar {
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
}

.help {
    background: transparent;
    border: none !important;
    // position: absolute;
    // left:75%;
}

.invoice-container {
    padding-bottom: 10px;
}

.table-header {
    display: flex;

    h4 {
        margin-right: 10px;
    }

    a {
        align-self: center;
        margin-bottom: 8px;
    }
}

.payment-options {
    button {
        margin-top: 15px;
    }
}

.disclaimer-container {
    font-size: 15px;
    max-width: 300px;
}

.warn-text {
    color: #ff0000;
}

.tooltip-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.success-snackbar {
    width: max-content;
    background: #3f8d02;
}

.error-snackbar {
    width: max-content;
    background: #e30d0d;
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
    background-color: unset !important;
}

@include themes.all;

.mat-table {
    display: block;
    font-family: inherit;
}

.mat-row,
.mat-header-row {
    border-bottom-width: 1px;
    align-items: center;
}

.mat-cell,
.mat-header-cell {
    overflow: hidden;
}
